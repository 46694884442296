import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { extract } from '../../../utils/graphql'
import { localizePath } from '../../../../config/i18n'

import EmptyPage from '../../../components/Templates/EmptyPage'
import WysiwygContent from '../../../components/General/WysiwygContent'
import NoTranslation from '../../../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'

class HubspotForm extends Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = '//js-eu1.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '26119259',
          formId: '51d4699c-457d-4b7f-a7c1-2f365a648b7c',
          target: '#hubspotForm',
        })
      }
    })
  }

  render() {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    )
  }
}

const EnterprisePage = ({ data, pageContext }) => {
  const { prismicEnterprise } = data
  const page = prismicEnterprise
  if (!page) return <NoTranslation></NoTranslation>

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      isThick: true,
      parent: {
        path: localizePath(pageContext.locale, `/get-in-touch`),
        text: 'Get in touch',
      },
    }),
  }
  return (
    <EmptyPage {...hydratedData}>
      <HubspotForm />
      <div className="mt-4">
        <WysiwygContent>
          {page.data.content && page.data.content.html && (
            <div
              dangerouslySetInnerHTML={{ __html: page.data.content.html }}
            ></div>
          )}
        </WysiwygContent>
      </div>
    </EmptyPage>
  )
}

export default withPreview(EnterprisePage)

export const pageQuery = graphql`
  query EnterpriseByLocale($locale: String!) {
    prismicEnterprise(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        mantel_content {
          html
        }
        content {
          html
        }
      }
    }
  }
`
